<template>
  <div
    class="d-flex flex-column flex-root bgi-no-repeat"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
    style="background-size: 100% 100%"
  >
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bgi-position-y-bottom bgi-position-x-center
      "
      :class="{
        'login-signin-on': this.state == 'signin',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto">
        <div class="d-flex flex-column-auto flex-column pt-15"></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
          bgi-no-repeat
        "
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form">
              <div class="d-flex flex-column-auto flex-column">
                <a href="#" class="text-center mb-10">
                  <img src="/media/logos/logo_bumn.png" class="max-w-120px mx-1" alt />
                  <img src="/media/logos/logo_pupuk.png" class="max-w-120px mx-1" alt />
                  <img src="/media/logos/logo_2.png" class="max-w-120px mx-1" alt />
                  <img src="/media/logos/logo_1.png" class="max-w-200px mx-1" alt />
                </a>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Username</label>
                <div id="example-input-group-1" label label-for="example-input-1">
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="text"
                    name="username"
                    ref="username"
                    v-model="form.username"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                </div>
                <div id="example-input-group-2" label label-for="example-input-2">
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core'

// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger'
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap'
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton'

import KTUtil from '@/assets/js/components/util'
import { mapGetters, mapState } from 'vuex'
import { LOGIN, LOGOUT } from '@/core/services/store/auth.module'
// import JwtService from "@/core/services/jwt.service";

export default {
  name: 'login-1',
  data() {
    return {
      state: 'signin',
      // Remove this dummy login info
      form: {
        username: '',
        password: '',
      },
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
    ...mapGetters(['currentUser']),

    backgroundImage() {
      return (
        //process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
        process.env.BASE_URL + 'media/pabrik.jpeg'
      )
    },
  },
  mounted() {
    const signin_form = KTUtil.getById('kt_login_signin_form')

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: 'Username is required',
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: 'Password is required',
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    })

    this.fv.on('core.form.valid', () => {
      var username = this.form.username
      var password = this.form.password

      // clear existing errors
      this.$store.dispatch(LOGOUT)

      // set spinner to submit button
      const submitButton = this.$refs['kt_login_signin_submit']
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { username, password })
          // go to which page after successfully login
          .then(data => {
            if (data.status == 'ok') {
              if (data.isDashboard == 'true') {
                this.$router.push({ name: 'home' })
              } else {
                this.$router.push({ name: 'Create Report' })
              }
            } else if (data.status == 'gagal') {
              this.makeToast('danger', data.message)
            }
          })
          .catch(() => {})

        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right')
      }, 2000)
    })
  },
  methods: {
    showForm(form) {
      this.state = form
      var form_name = 'kt_login_' + form + '_form'
      KTUtil.animateClass(KTUtil.getById(form_name), 'animate__animated animate__backInUp')
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: 'Pesan',
        variant: variant,
        toaster: 'b-toaster-top-right',
        solid: true,
      })
    },
  },
}
</script>